import { get } from "lodash"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { CustomAccordion } from "../HomeComponents/Accordion"
import { AdvantagesForHowToId } from '../HomeComponents/Advantages'

export const HowToComponents = ({ json, isEnglish, location }) => {
  const [defaultKey, setDefaultKey] = useState("0")
  const getHash = get(location, "hash", "")
  const { Verified, Pros, Transfer } = json
  const StepData = [
    {
      eventId: "0",
      idName: "pros",
      name: Pros.Title,
      customBody: <AdvantagesForHowToId props={Pros}/>
    },
    {
      eventId: "1",
      idName: "verified",
      name: Verified.Title,
      bodyData: [
        {
          eventId: "0",
          name: Verified.Point1,
          content: Verified.Desc1,
        },
        {
          eventId: "1",
          name: Verified.Point2,
          content: Verified.Desc2,
        },
        {
          eventId: "2",
          name: Verified.Point3,
          content: Verified.Desc3,
        },
        {
          eventId: "3",
          name: Verified.Point4,
          content: Verified.Desc4,
        },
        {
          eventId: "4",
          name: Verified.Point5,
          content: Verified.Desc5,
        },
        {
          eventId: "5",
          name: Verified.Point6,
          content: Verified.Desc6,
        },
        {
          eventId: "6",
          name: Verified.Point7,
          content: Verified.Desc7,
        },
        {
          eventId: "7",
          name: Verified.Point8,
          content: Verified.Desc8,
        }
      ],
    },
    {
      eventId: "2",
      idName: "transfer",
      name: Transfer.Title,
      bodyData: [
        {
          eventId: "0",
          name: Transfer.Point1,
          content: Transfer.Desc1,
        },
        {
          eventId: "1",
          name: Transfer.Point2,
          content: Transfer.Desc2,
        },
        {
          eventId: "2",
          name: Transfer.Point3,
          content: Transfer.Desc3,
        },
        {
          eventId: "3",
          name: Transfer.Point4,
          content: Transfer.Desc4,
        },
        {
          eventId: "4",
          name: Transfer.Point5,
          content: Transfer.Desc5,
        },
        {
          eventId: "5",
          name: Transfer.Point6,
          content: Transfer.Desc6,
        },
        {
          eventId: "6",
          name: Transfer.Point7,
          content: Transfer.Desc7,
        },
        {
          eventId: "7",
          name: Transfer.Point8,
          content: Transfer.Desc8,
        },
        {
          eventId: "8",
          name: Transfer.Point9,
          content: Transfer.Desc9,
        },
        {
          eventId: "9",
          name: Transfer.Point10,
          content: Transfer.Desc10,
        },
      ],
    }
  ]

  useEffect(() => {
    if (getHash === "#pros") {
      setDefaultKey("0")
    } else if (getHash === "#verified") {
      setDefaultKey("1")
    } else if (getHash === "#transfer") {
      setDefaultKey("2")
    } 
    // else if (getHash === "#verified") {
    //   setDefaultKey("3")
    // }
  }, [getHash])

  return (
    <>
      <Container style={{ paddingTop: 120 }}>
        <CustomAccordion data={StepData} isHowTo defaultKey={defaultKey} />
      </Container>
    </>
  )
}
